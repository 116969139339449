import { getInvitationByToken } from "src/api"

export const setInvitationByToken = (invitationToken, invitation) => ({
  type: "invitationByToken/SET_INVITATION",
  invitationToken,
  payload: invitation,
})

export const setLoading = (invitationToken, loading) => ({
  type: "invitationByToken/SET_LOADING",
  invitationToken,
  payload: loading,
})

export const setError = (invitationToken, error) => ({
  type: "invitationByToken/SET_ERROR",
  invitationToken,
  payload: error,
})

export const fetchInvitation = invitationToken => async dispatch => {
  dispatch(setLoading(invitationToken, true))
  try {
    const invitation = await getInvitationByToken(invitationToken)
    dispatch(setInvitationByToken(invitationToken, invitation))
  } catch (error) {
    dispatch(setError(invitationToken, error))
  } finally {
    dispatch(setLoading(invitationToken, false))
  }
}
