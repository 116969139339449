import React, { useEffect } from "react"
import Layout from "../components/layout"
import Invitation from "../components/invitation"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import * as BackgroundImg from "../images/backgrounds/Good/clouds.jpg"
import { useSelector, useDispatch } from "react-redux"
import { levelEvaluationTokenSelector } from "../store/levelEvaluationToken/selectors"
import { fetchLevelEvaluationByToken } from "../store/levelEvaluationByToken/actions"
import { levelEvaluationByTokenSelector } from "../store/levelEvaluationByToken/selectors"
import { navigate } from "gatsby-plugin-intl/link"

const InvitationPage = () => {
  // We check if a token is already in store, so we can jump the user to where he left off with the evaluation
  const { result: token } = useSelector(levelEvaluationTokenSelector)
  const { result } = useSelector(levelEvaluationByTokenSelector(token))

  const dispatch = useDispatch()

  useEffect(() => {
    if (token) {
      dispatch(fetchLevelEvaluationByToken(token))
    }
  }, [token])

  useEffect(() => {
    if (result && !result?.finallevel && result.token) {
      navigate(`/?levelevaluation=${result.token}`)
    }
  }, [result])

  return (
    <Layout type="hr" noHeaderOptions stretchBackground>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Atolo - Eval | Invitation</title>
      </Helmet>
      <Background className="flex-1" src={BackgroundImg}>
        <div className="w-full h-full justify-center items-center ">
          <Invitation />
        </div>
      </Background>
    </Layout>
  )
}

export default InvitationPage

const Background = styled.div`
  display: inline-block;
  z-index: 2;
  transition: visibility 1s linear;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  justify-self: center;
`
