import React from "react"
import TextInput from "../../base/forms/textInput"
import Select from "../../base/forms/select"
import { dropdownLanguageOptions } from "../../../utils/language"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../utils/hooks/useFormatMessage"

import Label from "../../base/forms/label"

const messages = defineMessages({
  firstNameLabel: "First name",
  lastNameLabel: "Last name",
  emailLabel: "Email",
  companyLabel: "Company",
  fillOutFormText: "Please fill out this form to register.",
  interfaceLabel: "In what language would you like to receive the instructions?",
})

const NewPerson = ({ setPerson, person, errors, showErrors }) => {
  const formatMessage = useFormatMessage()

  const handleChange = e => {
    setPerson({ ...person, [e.target.name]: e.target.value })
  }

  return (
    <div className="flex flex-col justify-between items-center">
      <p className="mb-2">{formatMessage(messages.fillOutFormText)}</p>
      <TextInput
        placeholder={formatMessage(messages.firstNameLabel)}
        name="firstname"
        value={person.firstname}
        onChange={handleChange}
        required
        invalid={showErrors && errors.firstname}
        errors={errors.firstname}
        className="my-1 w-64"
      />
      <TextInput
        placeholder={formatMessage(messages.lastNameLabel)}
        name="lastname"
        value={person.lastname}
        onChange={handleChange}
        required
        invalid={showErrors && errors.lastname}
        errors={errors.lastname}
        className="my-1 w-64"
      />
      <TextInput
        placeholder={formatMessage(messages.emailLabel)}
        name="email"
        value={person.email}
        onChange={handleChange}
        required
        invalid={showErrors && errors.email}
        errors={errors.email}
        className="my-1 w-64"
      />
      <TextInput
        placeholder={formatMessage(messages.companyLabel)}
        name="theirref1"
        value={person.theirref1}
        onChange={handleChange}
        required
        invalid={showErrors && errors.theirref1}
        errors={errors.theirref1}
        className="my-1 w-64"
      />
      <Label className="flex items-center space-y-4 mt-4 mb-4 w-full" large name={formatMessage(messages.interfaceLabel)}>
        <Select
          value={person.commlangcode}
          name="commlangcode"
          onChange={commlangcode => setPerson({ ...person, commlangcode })}
          options={dropdownLanguageOptions}
          required
          invalid={showErrors && errors.commlangcode}
          className="my-1"
        />
      </Label>
    </div>
  )
}

export default NewPerson
