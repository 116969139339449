import React, { useState, useEffect } from "react"
import { useWindowSize } from "../../utils/hooks/useWindowSize"
import queryString from "query-string"
import Modal from "../layout/modal"
import Button from "../base/buttons/regular"
import notifications from "../../utils/notifications"
import NewPerson from "./components/newPerson"
import { getErrors } from "./components/utils/validations"
import useAcceptInvitation from "../../utils/hooks/useAcceptInvitation"
import { defineMessages } from "gatsby-plugin-intl"
import { useSelector } from "react-redux"
import { useFetching } from "../../utils/hooks/useFetch"
import { fetchInvitation } from "../../store/invitationByToken/actions"
import { invitationSelector } from "../../store/invitationByToken/selectors"
import useFormatMessage from "../../utils/hooks/useFormatMessage"
import { getLanguageName } from "../../utils/language"
import Loader from "../base/loaders/loader"
import { navigate } from "gatsby-plugin-intl/link"
import { useIntroLogo } from "src/utils/hooks/useCompanyLogo"

const messages = defineMessages({
  acceptInvitationTitle: "Invitation",
  acceptInvitationErrorNotificationTitle: "Oops! Something went wrong.",
  acceptInvitationSuccessNotificationTitle: "You have accepted the invitation!",
  acceptInvitationSuccessNotificationMessage: "You should receive an email shortly",
  submitInvitationFormButton: "Go!",
  invitationSubtitle: "{company} invites you to take a language test.",
  invitationTitle: "Invitation",
  languageTested: "Language to be evaluated: {language}",
})

const Invitation = () => {
  const token = queryString.parse(window.location.search).token || "redfin-bepile-hoop"
  if (!token) {
    window.location.href = "https://www.atolo.eu/"
  }

  const [navigationVisible, setNavigationVisible] = useState(true)
  const [widthOfModal, setWidthOfModal] = useState("w-3/5")
  const [person, setPerson] = useState({ firstname: "", lastname: "", email: "", theirref1: "", commlangcode: "fr" })

  const formatMessage = useFormatMessage()
  const currentSize = useWindowSize()
  useFetching(fetchInvitation(token), token)
  const { loading: invitationLoading, error: invitationError, result: invitation } = useSelector(invitationSelector(token))
  const { loading, acceptInvitation } = useAcceptInvitation()

  useEffect(() => {
    if (invitationError) {
      notifications.error(formatMessage(messages.acceptInvitationErrorNotificationTitle), invitationError.message, invitationError.details)
    }
  }, [invitationError])

  const errors = getErrors(person)

  /* Determine if navigation arrows should be displayed based on window width */
  if (["sm", "md"].includes(currentSize) && navigationVisible) {
    setNavigationVisible(false)
    setWidthOfModal("w-full")
  } else if (!["sm", "md"].includes(currentSize) && !navigationVisible) {
    setNavigationVisible(true)
    setWidthOfModal("w-3/5")
  }

  const handle = async () => {
    try {
      const levelEvaluationToken = await acceptInvitation(token, {
        ...person,
        commonname: `${person.firstname} ${person.lastname}`,
        companyuuid: invitation.companyuuid,
      })

      notifications.success(
        formatMessage(messages.acceptInvitationSuccessNotificationTitle),
        formatMessage(messages.acceptInvitationSuccessNotificationMessage)
      )

      navigate(`/?levelevaluation=${levelEvaluationToken.token}`)
    } catch (error) {
      notifications.error(formatMessage(messages.acceptInvitationErrorNotificationTitle), error.message, error.details)
    }
  }

  const header = useIntroLogo()

  return (
    <Modal noTitle mdWidth={widthOfModal} navigation={false} mobile={!navigationVisible} lgWidth="w-2/5" next="/admin">
      {header && <img className="w-64 m-auto -mb-4 -mt-8" src={header} alt="Logo" />}
      <div className="text-gray-700 text-2xl text-center font-semibold">Invitation</div>
      {invitation && (
        <div className="flex flex-col items-center mt-5 space-y-4">
          <div>
            <span className="font-bold">{invitation.company.companyname}</span>
            {/* the company variable is empty, as it should be in bold (see line above) */}
            {formatMessage(messages.invitationSubtitle, { company: "" })}
          </div>
          <div>
            {/* the language variable is empty, as it should be in bold (see line below) */}
            {formatMessage(messages.languageTested, { language: "" })}
            <span className="font-bold">{getLanguageName(invitation.evaluatedlanguage.toUpperCase())}</span>
          </div>
        </div>
      )}
      {invitationLoading && <Loader />}
      <div className="mt-5 w-full items-center flex flex-col">
        <NewPerson person={person} setPerson={setPerson} errors={errors} />
      </div>
      <div className="flex flex-col justify-center items-center w-full">
        <div className="w-1/2">
          <Button primary className="text-center w-full" onClick={handle} loading={loading}>
            {formatMessage(messages.submitInvitationFormButton)}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default Invitation
