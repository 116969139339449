import { useState } from "react"
import { acceptInvitation, getPersonsByEmail } from "src/api"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { getAPI_CALL_STARTED } from "../gaConstants"
import { v4 } from "uuid"

export default () => {
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const execute = async (token, person) => {
    try {
      trackCustomEvent(getAPI_CALL_STARTED("ACCEPT_INVITATION"))
      setLoading(true)
      setError(undefined)

      // First check if person with this email exists already, if so we use that id
      let uuid = v4()
      try {
        const existingPersons = await getPersonsByEmail(person.email, person.companyuuid)
        if (existingPersons.length > 0) {
          uuid = existingPersons[0].uuid
        }
      } catch {
        // fail silently
      }

      const data = await acceptInvitation(token, { ...person, uuid })
      return data
    } catch (error) {
      Sentry.captureException(error)
      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  return { error, loading, acceptInvitation: execute }
}
